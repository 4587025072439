<template>
  <div class="foot">
    <div class="container">
      <footer class="row">
        <div class="footer-col col">
          <div>
            <h4>Products</h4>
            <ul>
              <li>
                <!-- <b-link :to="{ name: 'ProductCategory' }">ERP System</b-link> -->
              </li>
            </ul>
          </div>
          <div>
            <h4>About Us</h4>
            <ul>
              <li>
                <b-link :to="{ name: 'OurStory' }">Our Story</b-link>
              </li>
              <li>
                <b-link :to="{ name: 'CompanyVision' }">Company Vision</b-link>
              </li>
              <li>
                <b-link :to="{ name: 'MissionCoreValues' }"
                  >Mission & Core Values</b-link
                >
              </li>
              <li>
                <b-link :to="{ name: 'WhyUs' }">Why Us </b-link>
              </li>
            </ul>
          </div>

          <div>
            <h4>Support</h4>
            <ul>
              <li>
                <b-link :to="{ name: 'OurStory' }">Apps</b-link>
              </li>
              <li>
                <b-link :to="{ name: 'CompanyVision' }">Software</b-link>
              </li>
              <li>
                <b-link :to="{ name: 'MissionCoreValues' }"
                  >Merchandising Material</b-link
                >
              </li>
              <li>
                <b-link :to="{ name: 'WhyUs' }">Video</b-link>
              </li>
            </ul>
          </div>

          <div class="col-contact newsletter">
            <h4>Contact Us</h4>
            <ul>
              <li>
                <b-link :to="{ name: 'ContactUs' }">TBS</b-link>
              </li>

              <li>
                <b-link :to="{ name: 'SalesEnquiry' }">Sales Enquiry </b-link>
              </li>
              <li>
                <b-link :to="{ name: 'TechnicalEnquiry' }"
                  >Technical Enquiry
                </b-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="row copyright" style="width: 100%;">
          <div class="col-xs-12 copy col-sm-6">
            &copy;2020
            <a
              href="https://www.acs.com.hk/en/"
              title="Advanced Card Systems Ltd"
              >TBS Solutions Sdn Bhd</a
            >
            . All Rights Reserved.
          </div>
          <div class="col-xs-12 social col-sm-6">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/"
                  title="Facebook"
                  target="_blank"
                >
                  <i class="ion-social-facebook" aria-hidden="true"
                    ><font-awesome-icon :icon="['fab', 'facebook']"
                  /></i>
                  <span>Facebook</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>
