import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueLodash from "vue-lodash";
import lodash from "lodash";

Vue.config.productionTip = false;

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
library.add(faFacebook, faArrowUp);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueLodash, { lodash: lodash });

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router";
import "./assets/css/main.css";
import "./assets/css/nav.css";
import "./assets/css/media-queries.css";

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
