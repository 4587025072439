import Vue from "vue";
import VueRouter from "vue-router";
import { BasicLayout } from "@/layouts";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: BasicLayout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/Home.vue")
      },
      {
        path: "/product-category/:id",
        name: "ProductCategory",
        component: () => import("@/views/product/ProductCategory.vue")
      },
      {
        path: "/product",
        name: "Product",
        component: () => import("@/views/product/Product.vue")
      },
      {
        path: "/about-us/our-story",
        name: "OurStory",
        component: () => import("@/views/aboutUs/OurStory.vue")
      },
      {
        path: "/about-us/company-vision",
        name: "CompanyVision",
        component: () => import("@/views/aboutUs/CompanyVision.vue")
      },
      {
        path: "/about-us/mission-and-core-values",
        name: "MissionCoreValues",
        component: () => import("@/views/aboutUs/MissionCoreValues.vue")
      },
      {
        path: "/about-us/why-us",
        name: "WhyUs",
        component: () => import("@/views/aboutUs/WhyUs.vue")
      },
      {
        path: "/about-us/corportate-profile",
        name: "CorportateProfile",
        component: () => import("@/views/aboutUs/CorportateProfile.vue")
      },
      {
        path: "/support/apps",
        name: "Apps",
        component: () => import("@/views/support/Apps.vue")
      },
      {
        path: "/support/software",
        name: "Software",
        component: () => import("@/views/support/Software.vue")
      },
      {
        path: "/support/merchandising-material",
        name: "MerchandisingMaterial",
        component: () => import("@/views/support/MerchandisingMaterial.vue")
      },
      {
        path: "/support/video",
        name: "Video",
        component: () => import("@/views/support/Video.vue")
      },
      {
        path: "/support/demo",
        name: "Demo",
        component: () => import("@/views/support/Demo.vue")
      },
      {
        path: "/support/event-calendar",
        name: "EventCalendar",
        component: () => import("@/views/support/EventCalendar.vue")
      },
      {
        path: "/support/gallery-photo",
        name: "GalleryPhoto",
        component: () => import("@/views/support/GalleryPhoto.vue")
      },
      {
        path: "/support/sla-agreement",
        name: "SLAAgreement",
        component: () => import("@/views/support/SLAAgreement.vue")
      },
      {
        path: "/support/form",
        name: "Form",
        component: () => import("@/views/support/Form.vue")
      },
      {
        path: "/contact-us",
        name: "ContactUs",
        component: () => import("@/views/contactUs/ContactUs.vue")
      },
      {
        path: "/contact-us/sales-enquiry",
        name: "SalesEnquiry",
        component: () => import("@/views/contactUs/SalesEnquiry.vue")
      },
      {
        path: "/contact-us/technical-enquiry",
        name: "TechnicalEnquiry",
        component: () => import("@/views/contactUs/TechnicalEnquiry.vue")
      },
      {
        path: "/contact-us/clinic",
        name: "Clinic",
        component: () => import("@/views/contactUs/Clinic.vue")
      },
      {
        path: "/contact-us/webinar",
        name: "Webinar",
        component: () => import("@/views/contactUs/Webinar.vue")
      },
      {
        path: "/contact-us/training",
        name: "Training",
        component: () => import("@/views/contactUs/Training.vue")
      },
      {
        path: "/sme-grant",
        name: "SMEGrant",
        component: () => import("@/views/smeGrant/index.vue")
      },
      {
        path: "/sme-grant/enquiry",
        name: "Enquiry",
        component: () => import("@/views/smeGrant/Enquiry.vue")
      },
      {
        path: "/sme-grant/apply",
        name: "Apply",
        component: () => import("@/views/smeGrant/Apply.vue")
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
