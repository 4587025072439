<template>
  <div
    class="head head-2020"
    :class="{ fixed: headFixed }"
    :style="{ top: headFixed ? '0%' : '-100%' }"
  >
    <div class="container">
      <header>
        <div class="emblem">
          <router-link :to="{ name: 'Home' }">
            <img src="../assets/img/logo.jpg" alt="https://www.acs.com.hk"
          /></router-link>
        </div>
        <div class="navigations">
          <div class="header-top">
            <!-- logo text -->
            <!-- <a class="logo-text" href="https://www.acs.com.hk/en">
                <img
                  src="../assets/img/logo.jpg"
                  alt="https://www.acs.com.hk"
                />
              </a> -->
            <!-- mobile nav -->
            <div class="mobile-menus float-right">
              <!--<button class="ion-android-search btn-mobile-search-toggle"></button>-->
              <a
                class="btn-sidebar-toggle ion-android-menu"
                v-b-toggle.sidebar-1
                ><b-icon icon="list"></b-icon
              ></a>
            </div>

            <div class="top-menu float-right">
              <ul>
                <li>
                  <a
                    href="https://api.whatsapp.com/send?phone=60182613102/"
                    target="_blank"
                    ><img
                      src="whatsapp.png"
                      style="width: 100%; max-width: 200px"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="header-navigation">
            <div class="main-menu">
              <ul class="menu1">
                <li
                  @mouseenter="headerActive = 6"
                  @mouseleave="headerActive = 0"
                  :class="{ selected: headerActive === 6 }"
                >
                  <router-link
                    :to="{ name: 'SMEGrant' }"
                    @click.native="headerActive = 0"
                    >SME Grant
                  </router-link>
                </li>

                <li
                  @mouseenter="headerActive = 1"
                  @mouseleave="headerActive = 0"
                  :class="{ selected: headerActive === 1 }"
                >
                  <router-link
                    :to="{ name: 'Product' }"
                    @click.native="headerActive = 0"
                    >Products
                  </router-link>
                  <div class="menu2-wrapper">
                    <ul class="menu2">
                      <li
                        :class="{ active: menuActive == categoryIndex }"
                        @mouseover="menuActive = categoryIndex"
                        v-for="(category, categoryIndex) in products"
                        :key="category.title"
                      >
                        <b-link
                          :to="{
                            name: 'ProductCategory',
                            params: { id: category.title }
                          }"
                          @click.native="headerActive = 0"
                          >{{ category.title }}</b-link
                        >
                        <div class="menu3-wrapper">
                          <h2>{{ category.title }}</h2>
                          <div class="preview 1">
                            <div class="caption">
                              <ul class="menu3">
                                <li
                                  v-for="product in category.submenu"
                                  :key="product.title"
                                >
                                  <strong>{{ product.title }}</strong>
                                  <ul class="menu3">
                                    <li
                                      v-for="subproduct in product.submenu"
                                      :key="subproduct.title"
                                      @mouseover="
                                        menuInfo = {
                                          img: subproduct.img,
                                          title: subproduct.fullTitle
                                        }
                                      "
                                    >
                                      <router-link
                                        :to="{
                                          name: 'ProductCategory',
                                          params: { id: category.title }
                                        }"
                                        @click.native="headerActive = 0"
                                        >{{ subproduct.title }}</router-link
                                      >
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                            <div class="thumbnail">
                              <img :src="menuInfo.img" />
                              <h3>{{ menuInfo.title }}</h3>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  @mouseenter="headerActive = 5"
                  @mouseleave="headerActive = 0"
                  :class="{ selected: headerActive === 5 }"
                >
                  <router-link
                    :to="{ name: 'Product' }"
                    @click.native="headerActive = 0"
                    >Solutions
                  </router-link>
                  <div class="menu2-wrapper">
                    <ul class="menu2">
                      <li
                        :class="{ active: menuActive == solutionIndex }"
                        @mouseover="menuActive = solutionIndex"
                        v-for="(solution, solutionIndex) in solutions"
                        :key="solution.title"
                      >
                        <b-link
                          :to="{
                            name: 'ProductCategory',
                            params: { id: solution.title }
                          }"
                          @click.native="headerActive = 0"
                          >{{ solution.title }}</b-link
                        >
                        <div class="menu3-wrapper">
                          <h2>{{ solution.title }}</h2>
                          <div class="preview 1">
                            <div class="caption">
                              <ul class="menu3">
                                <li
                                  v-for="product in solution.submenu"
                                  :key="product.title"
                                >
                                  <strong>{{ product.title }}</strong>
                                  <ul class="menu3">
                                    <li
                                      v-for="subproduct in product.submenu"
                                      :key="subproduct.title"
                                      @mouseover="
                                        window.console.log('gg');
                                        menuInfo = {
                                          img: subproduct.img,
                                          title: subproduct.fullTitle
                                        };
                                      "
                                    >
                                      <router-link
                                        :to="{ name: 'Product' }"
                                        @click.native="headerActive = 0"
                                        >{{ subproduct.title }}</router-link
                                      >
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                            <div class="thumbnail">
                              <img :src="menuInfo.img" />
                              <h3>{{ menuInfo.title }}</h3>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  @mouseenter="headerActive = 2"
                  @mouseleave="headerActive = 0"
                  :class="{ selected: headerActive === 2 }"
                >
                  <router-link
                    :to="{ name: 'OurStory' }"
                    @click.native="headerActive = 0"
                    >About Us</router-link
                  >
                  <div class="menu2-wrapper">
                    <ul class="menu2">
                      <li
                        :class="{ active: menuActive == 1 }"
                        @mouseover="menuActive = 1"
                      >
                        <router-link
                          :to="{ name: 'OurStory' }"
                          @click.native="headerActive = 0"
                          >Our Story</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>Our Story</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'OurStory' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li
                        :class="{ active: menuActive == 2 }"
                        @mouseover="menuActive = 2"
                      >
                        <router-link
                          :to="{ name: 'CorportateProfile' }"
                          @click.native="headerActive = 0"
                        >
                          Corporate Mission , Vision & Values
                        </router-link>
                        <div class="menu3-wrapper">
                          <h2>Corporate Mission , Vision & Values</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'CorportateProfile' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <!-- <li
                        :class="{ active: menuActive == 3 }"
                        @mouseover="menuActive = 3"
                      >
                        <router-link
                          :to="{ name: 'MissionCoreValues' }"
                          @click.native="headerActive = 0"
                          >Mission & Core Values</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>Mission & Core Values</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'MissionCoreValues' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li> -->

                      <li
                        :class="{ active: menuActive == 3 }"
                        @mouseover="menuActive = 3"
                      >
                        <router-link
                          :to="{ name: 'WhyUs' }"
                          @click.native="headerActive = 0"
                          >Why Us</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>Why Us</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'WhyUs' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  @mouseenter="headerActive = 3"
                  @mouseleave="headerActive = 0"
                  :class="{ selected: headerActive === 3 }"
                >
                  <router-link
                    :to="{ name: 'Apps' }"
                    @click.native="headerActive = 0"
                    >Support</router-link
                  >
                  <div class="menu2-wrapper">
                    <ul class="menu2">
                      <!-- <li
                        :class="{ active: menuActive == 1 }"
                        @mouseover="menuActive = 1"
                      >
                        <router-link
                          :to="{ name: 'Apps' }"
                          @click.native="headerActive = 0"
                          >Apps</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>Apps</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'Apps' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li
                        :class="{ active: menuActive == 2 }"
                        @mouseover="menuActive = 2"
                      >
                        <router-link
                          :to="{ name: 'Software' }"
                          @click.native="headerActive = 0"
                          >Software</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>Software</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'Software' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li
                        :class="{ active: menuActive == 3 }"
                        @mouseover="menuActive = 3"
                      >
                        <router-link
                          :to="{ name: 'MerchandisingMaterial' }"
                          @click.native="headerActive = 0"
                          >Merchandising Material
                        </router-link>
                        <div class="menu3-wrapper">
                          <h2>Merchandising Material</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'MerchandisingMaterial' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li> -->

                      <li
                        :class="{ active: menuActive == 8 }"
                        @mouseover="menuActive = 8"
                      >
                        <router-link
                          :to="{ name: 'Video' }"
                          @click.native="headerActive = 0"
                          >Download
                        </router-link>

                        <div class="menu3-wrapper">
                          <h2>Download</h2>
                          <div class="preview 1">
                            <div class="caption">
                              <div class="menu3">
                                <li>
                                  <router-link :to="{ name: 'Apps' }">
                                    Apps</router-link
                                  >
                                </li>
                                <li>
                                  <router-link :to="{ name: 'Software' }">
                                    Software</router-link
                                  >
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name: 'MerchandisingMaterial' }"
                                  >
                                    Merchandising Material</router-link
                                  >
                                </li>
                                <li>
                                  <router-link :to="{ name: 'SLAAgreement' }">
                                    SLA Agreement</router-link
                                  >
                                </li>
                                <li>
                                  <router-link :to="{ name: 'Form' }">
                                    Form</router-link
                                  >
                                </li>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li
                        :class="{ active: menuActive == 4 }"
                        @mouseover="menuActive = 4"
                      >
                        <router-link
                          :to="{ name: 'Video' }"
                          @click.native="headerActive = 0"
                          >Video
                        </router-link>
                        <div class="menu3-wrapper">
                          <h2>Video</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'Video' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li
                        :class="{ active: menuActive == 5 }"
                        @mouseover="menuActive = 5"
                      >
                        <router-link
                          :to="{ name: 'Demo' }"
                          @click.native="headerActive = 0"
                          >Demo
                        </router-link>
                        <div class="menu3-wrapper">
                          <h2>Demo</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'Demo' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li
                        :class="{ active: menuActive == 6 }"
                        @mouseover="menuActive = 6"
                      >
                        <router-link
                          :to="{ name: 'EventCalendar' }"
                          @click.native="headerActive = 0"
                          >Event Calendar
                        </router-link>
                        <div class="menu3-wrapper">
                          <h2>Event Calendar</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'EventCalendar' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li
                        :class="{ active: menuActive == 7 }"
                        @mouseover="menuActive = 7"
                      >
                        <router-link
                          :to="{ name: 'GalleryPhoto' }"
                          @click.native="headerActive = 0"
                          >Gallery Photo
                        </router-link>
                        <div class="menu3-wrapper">
                          <h2>Gallery Photo</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'GalleryPhoto' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <!-- <li
                        :class="{ active: menuActive == 8 }"
                        @mouseover="menuActive = 8"
                      >
                        <router-link
                          :to="{ name: 'SLAAgreement' }"
                          @click.native="headerActive = 0"
                          >SLA Agreement
                        </router-link>
                        <div class="menu3-wrapper">
                          <h2>SLA Agreement</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'SLAAgreement' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li
                        :class="{ active: menuActive == 9 }"
                        @mouseover="menuActive = 9"
                      >
                        <router-link
                          :to="{ name: 'Form' }"
                          @click.native="headerActive = 0"
                          >Form
                        </router-link>
                        <div class="menu3-wrapper">
                          <h2>Form</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'Form' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li> -->
                    </ul>
                  </div>
                </li>

                <li
                  @mouseenter="headerActive = 4"
                  @mouseleave="headerActive = 0"
                  :class="{ selected: headerActive === 4 }"
                >
                  <router-link
                    :to="{ name: 'ContactUs' }"
                    @click.native="headerActive = 0"
                    ><span>Contact Us</span></router-link
                  >
                  <div class="menu2-wrapper" ref="refContactUs">
                    <ul class="menu2">
                      <li
                        :class="{ active: menuActive == 1 }"
                        @mouseover="menuActive = 1"
                      >
                        <router-link
                          :to="{ name: 'ContactUs' }"
                          @click.native="headerActive = 0"
                          >TBS</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>TBS</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                We deliver the most appropriate and cost
                                effective solutions. In additions, prior to
                                developing a system, we access the clients’
                                needs and provide business consultancy. After
                                developing the system, we train the users and
                                make sure the system is properly implemented and
                                maintained.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'ContactUs' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li
                        :class="{ active: menuActive == 2 }"
                        @mouseover="menuActive = 2"
                      >
                        <router-link
                          :to="{ name: 'SalesEnquiry' }"
                          @click.native="headerActive = 0"
                          >General Enquiry</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>General Enquiry</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                Inquire about product features, pricing, or
                                availability. Any information solicited for a
                                sales inquiry will be used solely to provide
                                requester with information and gather data to
                                improve future distribution of information.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'SalesEnquiry' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <!-- <li
                        :class="{ active: menuActive == 3 }"
                        @mouseover="menuActive = 3"
                      >
                        <router-link
                          :to="{ name: 'TechnicalEnquiry' }"
                          @click.native="headerActive = 0"
                          >Technical Enquiry</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>Technical Enquiry</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                Let us know if you have any technical inquiries,
                                regardless of whether you have purchased a
                                product yet. Any information solicited during
                                technical support will be used solely to resolve
                                issues and gather data to improve future support
                                activities.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'TechnicalEnquiry' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li> -->

                      <li
                        :class="{ active: menuActive == 3 }"
                        @mouseover="menuActive = 3"
                      >
                        <router-link
                          :to="{ name: 'Clinic' }"
                          @click.native="headerActive = 0"
                          >Clinic Enquiry</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>Clinic Enquiry</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                Let us know if you have any technical inquiries,
                                regardless of whether you have purchased a
                                product yet. Any information solicited during
                                technical support will be used solely to resolve
                                issues and gather data to improve future support
                                activities.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'Clinic' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>

                      <!-- <li
                        :class="{ active: menuActive == 4 }"
                        @mouseover="menuActive = 4"
                      >
                        <router-link
                          :to="{ name: 'Training' }"
                          @click.native="headerActive = 0"
                          >Online Training</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>Online Training</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                Let us know if you have any technical inquiries,
                                regardless of whether you have purchased a
                                product yet. Any information solicited during
                                technical support will be used solely to resolve
                                issues and gather data to improve future support
                                activities.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'Training' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li> -->

                      <li
                        :class="{ active: menuActive == 5 }"
                        @mouseover="menuActive = 5"
                      >
                        <router-link
                          :to="{ name: 'Webinar' }"
                          @click.native="headerActive = 0"
                          >Product Presentation</router-link
                        >
                        <div class="menu3-wrapper">
                          <h2>Product Presentation</h2>
                          <div class="preview corporate-profile">
                            <div class="caption">
                              <p></p>
                              <p>
                                Let us know if you have any technical inquiries,
                                regardless of whether you have purchased a
                                product yet. Any information solicited during
                                technical support will be used solely to resolve
                                issues and gather data to improve future support
                                activities.
                              </p>
                              <br />
                              <router-link
                                :to="{ name: 'Webinar' }"
                                @click.native="headerActive = 0"
                                class="btn-more"
                              >
                                Go to Page</router-link
                              >
                            </div>
                            <div class="thumbnail">
                              <img
                                data-default-img="page-headers-thumb/header_thumb_2_corporate.jpg"
                                a
                                data-img-src="page-headers-thumb/header_thumb_2_corporate.jpg"
                              />
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <!-- <form
                  class="float-right desktop-search search-form"
                  action="/action/forms/?lang=en&page=search"
                  method="POST"
                >
                  <input
                    type="hidden"
                    value="eb396822c5495fc9fd6b9fc55460bffb"
                    name="csrf_token"
                  />
                  <input
                    type="text"
                    name="keyword"
                    data-parsley-required="true"
                    data-parsley-required-message="Please enter a valid word or phrase to search"
                    data-parsley-minlength="3"
                    data-parsley-minlength-message="Please enter at least 3 characters"
                  />
                  <button type="submit" class="ion-android-search"></button>
                </form> -->
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import product from "@/assets/json/product.json";
import solutions from "@/assets/json/solutions.json";

export default {
  name: "Header",
  props: {
    headFixed: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      menuActive: 0,
      menuInfo: {},
      headerActive: 0,
      window: window,
      products: product,
      solutions: solutions
    };
  },
  methods: {
    hideMenu() {
      var self = this;
      self.$refs.refContactUs.style.display = "none";
      self.$nextTick(() => {
        self.$refs.refContactUs.style.display = null;
      });
    }
  }
};
</script>
