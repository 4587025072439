<template>
  <div>
    <Header :headFixed="scrollTopVisible" />

    <Sidebar />
    <div class="body">
      <router-view :key="$route.fullPath" />
    </div>

    <Footer />

    <button
      v-if="scrollTopVisible"
      id="btn-top"
      style="display: block; color: white;"
      @click="window.scrollTo(0, 0)"
    >
      <font-awesome-icon icon="arrow-up" size="lg" />
    </button>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
export default {
  data() {
    return {
      window: window,
      scrollTopVisible: false
    };
  },
  components: {
    Header,
    Footer,
    Sidebar
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      var self = this;
      if (window.innerHeight + window.pageYOffset >= 1500) {
        self.scrollTopVisible = true;
      } else {
        self.scrollTopVisible = false;
      }
    }
  }
};
</script>

<style scoped>
.menu2 li:hover .menu3-wrapper {
  display: block;
}
</style>
