<template>
  <b-sidebar id="sidebar-1" right shadow backdrop>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block variant="info" :to="{ name: 'SMEGrant' }"
          >SME Grant</b-button
        >
        <b-button block v-b-toggle.accordion-product variant="info"
          >Products</b-button
        >
        <b-button block v-b-toggle.accordion-solutions variant="info"
          >Solutions</b-button
        >
        <b-button block v-b-toggle.accordion-about-us variant="info"
          >About Us</b-button
        >

        <b-collapse
          id="accordion-about-us"
          role="tabpanel"
          accordion="accordion-sidebar"
        >
          <b-card-body>
            <b-list-group-item :to="{ name: 'OurStory' }"
              >Our Story</b-list-group-item
            >
            <b-list-group-item :to="{ name: 'CorportateProfile' }"
              >Corporate Mission , Vision & Values</b-list-group-item
            >
            <b-list-group-item :to="{ name: 'WhyUs' }"
              >Why Us
            </b-list-group-item>
          </b-card-body>
        </b-collapse>

        <b-button block v-b-toggle.accordion-support variant="info" class="mt-2"
          >Support</b-button
        >

        <b-collapse
          id="accordion-support"
          role="tabpanel"
          accordion="accordion-sidebar"
        >
          <b-card-body>
            <b-list-group-item v-b-toggle.accordion-download
              >Download</b-list-group-item
            >

            <b-collapse id="accordion-download" role="tabpanel">
              <b-card-body>
                <b-list-group-item :to="{ name: 'Apps' }"
                  >Apps</b-list-group-item
                >
                <b-list-group-item :to="{ name: 'Software' }"
                  >Software</b-list-group-item
                >
                <b-list-group-item :to="{ name: 'MerchandisingMaterial' }"
                  >Merchandising Material
                </b-list-group-item>
                <b-list-group-item :to="{ name: 'SLAAgreement' }"
                  >SLA Agreement
                </b-list-group-item>
                <b-list-group-item :to="{ name: 'Form' }"
                  >Form
                </b-list-group-item>
              </b-card-body>
            </b-collapse>

            <b-list-group-item :to="{ name: 'Video' }">Video</b-list-group-item>
            <b-list-group-item :to="{ name: 'Demo' }">Demo</b-list-group-item>
            <b-list-group-item :to="{ name: 'EventCalendar' }"
              >Event Calendar</b-list-group-item
            >
            <b-list-group-item :to="{ name: 'GalleryPhoto' }"
              >Gallery Photo</b-list-group-item
            >
          </b-card-body>
        </b-collapse>
        <b-button
          block
          v-b-toggle.accordion-contact-us
          variant="info"
          class="mt-2"
          >Contact Us</b-button
        >

        <b-collapse
          id="accordion-contact-us"
          role="tabpanel"
          accordion="accordion-sidebar"
        >
          <b-card-body>
            <b-list-group-item :to="{ name: 'ContactUs' }"
              >TBS</b-list-group-item
            >
            <b-list-group-item :to="{ name: 'SalesEnquiry' }"
              >General Enquiry</b-list-group-item
            >
            <!-- <b-list-group-item :to="{ name: 'TechnicalEnquiry' }"
              >Technical Enquiry</b-list-group-item
            > -->
            <b-list-group-item :to="{ name: 'Clinic' }"
              >Clinic</b-list-group-item
            >
            <b-list-group-item :to="{ name: 'Training' }"
              >Online Training</b-list-group-item
            >
            <b-list-group-item :to="{ name: 'Webinar' }"
              >Webinar</b-list-group-item
            >
          </b-card-body>
        </b-collapse>
      </b-card-header>
    </b-card>
    <!-- <b-list-group>
      <b-list-group-item v-b-toggle.collapse-2>
        About Us
      </b-list-group-item>
      <b-collapse id="collapse-2" accordion="my-accordion">
        <div>
          <b-list-group-item :to="{ name: 'OurStory' }">
            Our Story
          </b-list-group-item>
          <b-list-group-item :to="{ name: 'CompanyVision' }"
            >Company Vision
          </b-list-group-item>
          <b-list-group-item :to="{ name: 'MissionCoreValues' }"
            >Mission & Core Values
          </b-list-group-item>
          <b-list-group-item :to="{ name: 'WhyUs' }">
            Why Us
          </b-list-group-item>
        </div>
      </b-collapse>
      <b-list-group-item v-b-toggle.support>Support</b-list-group-item>
      <b-collapse id="support" accordion="my-accordion">
        <b-list-group>
          <b-list-group-item :to="{ name: 'Apps' }">Apps</b-list-group-item>
          <b-list-group-item :to="{ name: 'Software' }"
            >Software</b-list-group-item
          >
          <b-list-group-item :to="{ name: 'MerchandisingMaterial' }"
            >Merchandising Material</b-list-group-item
          >
          <b-list-group-item :to="{ name: 'Video' }">Video</b-list-group-item>
          <b-list-group-item :to="{ name: 'Demo' }">Demo</b-list-group-item>
          <b-list-group-item :to="{ name: 'EventCalendar' }"
            >Event Calendar</b-list-group-item
          >
          <b-list-group-item>Gallery Photo</b-list-group-item>
          <b-list-group-item>SLA Agreement</b-list-group-item>
          <b-list-group-item>Form</b-list-group-item>
        </b-list-group>
      </b-collapse>

      <b-list-group-item v-b-toggle.contactUs>Contact Us</b-list-group-item>
      <b-collapse id="contactUs" accordion="my-accordion">
        <b-list-group>
          <b-list-group-item :to="{ name: 'ContactUs' }">TBS</b-list-group-item>
          <b-list-group-item :to="{ name: 'SalesEnquiry' }"
            >Sales Enquiry</b-list-group-item
          >
          <b-list-group-item :to="{ name: 'TechnicalEnquiry' }"
            >Technical Enquiry</b-list-group-item
          >
        </b-list-group>
      </b-collapse>
    </b-list-group> -->
  </b-sidebar>
</template>

<script>
export default {
  name: "Sidebar"
};
</script>
